@import "base";
#contents {
	#slideshow {
		position: relative;
		margin-bottom: 10px;
		p {
			margin: 0;
		}
		ul {
			position: absolute;
			top: 0;
			left: 0;
			width: 400%;
			li {
				width: 25%;
				float: left;
			}
		}
	}
	p {
		margin: 0 10px 10px;
	}
	h2 {
		margin: 0 10px 10px;
	}
	hr {
		height: 1px;
		background: #eadbbe;
		margin: 10px 0;
	}
	#collection {
		margin: 0 10px 0 5px;
		li {
			display: inline-block;
			vertical-align: top;
			width: 25%;
			@include borderBox;
			padding: 0 0 0 5px;
			margin: 0 0 5px;
			@include box_shadow(0 0 3px rgba(233, 205, 154, 0.4))
		}
	}
	#create_team_card {
		background: #FFF;
		border-top: #eadbbe 1px solid;
		padding: 10px 0 10px;
		p {
			strong {
				font-weight: bold;
			}
		}
	}
}